import React from "react"
import disenoCss from "../../css/diseno.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// la imagen
import Video from "../../components/images/Video/Video"
import MenuDiseno from "../../components/menuDiseno/menuDiseno"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Fotografia = () => (
  <>
    <div className={disenoCss.background}>
      <div className={disenoCss.gradient}></div>
    </div>
    <Video></Video>
    <Link to="/" className={disenoCss.logoBlanco}>
      <img src={LogoBlanco} alt="Logotipo de engrane version blanca" />
    </Link>
    <MenuDiseno></MenuDiseno>
    <div className={disenoCss.container}>
      <Layout siteTitle="Diseño">
        <SEO title="Desarrollo Web" />
        <div className={disenoCss.contenido}>
          <h1 className={disenoCss.titulo}>
            tell <br />
            historys
          </h1>
          <p className={disenoCss.text}>
            ¿Te gustaría que a tu marca no solo la ubiquen sino también que la
            recuerden a cada momento? Nosotros te ayudamos con toda la
            estrategia para poder crear videos corporativos, de testimonio o de
            valor para tu marca para que tus clientes te puedan ubicar de mejor
            y gracias a esto se cree una mayor confianza a tu marca.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={disenoCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Fotografia
